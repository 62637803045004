<template>
  <v-app>
    <div id="app">
        <Navbar></Navbar>
        <router-view></router-view>
    </div>
  </v-app>
</template>

<script>
  import Navbar from './components/Navbar.vue'

  export default {
    components: {
      Navbar,
    }
  }
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  /* margin-top: 60px; */
}
</style>
