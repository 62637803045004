<template>
    <v-container>
        <MemberList></MemberList>
    </v-container>
</template>


<script>
import MemberList from '../components/MemberList'

export default {
    components:{MemberList},

}
</script>